.main {
    background-color: #101522;
    padding: 18px 19px;
    margin-bottom: 10px;
    margin-top: 0px !important;
 
  }
  .divider {
    --bs-breadcrumb-divider: "-";
  }
  .title1 {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    margin-top: 0px;
    padding: 7px;
    padding-left: 32px;
    margin-bottom: 0px !important;
    color: #fff;
  }
  
  .bread-active{
    color: #3b5998;
    font-weight: 500;
  }
  .bread-inactive{
    color: #abafb3;
    font-weight: 500;
    cursor: pointer;
  }
  